import { FolderIcon, ListIcon } from "lucide-react";
import { useRef, useState, type KeyboardEvent } from "react";
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/components/ui/context-menu";
import { Input } from "~/components/ui/input";
import { SidebarMenu, SidebarMenuItem } from "~/components/ui/sidebar";
import { useFolders } from "~/providers/FolderProvider";
import { useLists } from "~/providers/ListsProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";

const CreateNestedLists = ({
  children,
  folderId,
  folderName,
}: {
  children: React.ReactNode;
  folderId?: string;
  folderName?: string;
}) => {
  const { createList } = useLists();
  const { createFolder } = useFolders();

  const [creatingList, setCreatingList] = useState(false);
  const [newListName, setNewListName] = useState("");
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const { activeTeamAccount } = useTeamAccount();
  const { teamAccountUser } = useTeamAccountUser();

  const handleEnterList = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      createList({
        folderId,
        name: newListName,
        teamAccountId: activeTeamAccount.id,
        userId: teamAccountUser?.id as string,
        contactIds: [],
        mode: "LIGHT",
      });
      setNewListName("");
      setCreatingList(false);
    }
  };
  const handleEnterFolder = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      createFolder({
        name: newFolderName,
        teamAccountId: activeTeamAccount.id,
        parentFolderId: folderId,
      });
      setNewFolderName("");
      setCreatingFolder(false);
    }
  };
  const listInputRef = useRef<HTMLInputElement>(null);
  const folderInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <ContextMenuTrigger>
        <SidebarMenu>
          {children}
          {creatingList && (
            <SidebarMenuItem className="flex gap-4 ">
              <div className="flex items-center gap-4 px-2">
                <ListIcon className="w-4 h-4" />
                <Input
                  onKeyDown={handleEnterList}
                  ref={listInputRef}
                  onChange={(e) => setNewListName(e.target.value)}
                  placeholder="New List"
                />
              </div>
            </SidebarMenuItem>
          )}
          {creatingFolder && (
            <SidebarMenuItem className="flex gap-4 ">
              <div className="flex items-center gap-4 px-2">
                <FolderIcon className="w-4 h-4" />
                <Input
                  onKeyDown={handleEnterFolder}
                  ref={folderInputRef}
                  onChange={(e) => setNewFolderName(e.target.value)}
                  placeholder="New Folder"
                />
              </div>
            </SidebarMenuItem>
          )}
        </SidebarMenu>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem
          ref={listInputRef}
          onClick={() => setCreatingList(true)}
        >
          Create list {folderName && `in ${folderName}`}
        </ContextMenuItem>
        <ContextMenuItem
          ref={folderInputRef}
          onClick={() => setCreatingFolder(true)}
        >
          Create folder {folderName && `in ${folderName}`}
        </ContextMenuItem>
      </ContextMenuContent>
    </>
  );
};

export default CreateNestedLists;
