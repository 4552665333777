import {
  ADD_REPO_CONTACT,
  ADD_USER_CONTACT,
  REMOVE_CONTACT,
  SET_REMOVED_CONTACTS,
  SET_SEARCHING,
  SET_SHOW_ORGS,
  SET_SHOW_REPOS,
  SET_FULL_ORG,
  SET_SHOW_USERS,
  SET_HAS_TOO_MANY_CONTACTS,
  SET_ACTIVE_CONTACTS,
  REMOVE_ALL_CONTACTS,
  REMOVE_CONTACT_TEMP,
} from "./ContactSearchActions";
import {
  type ActionOptionsWithPayload,
  type ContactSearchContextType,
} from "./ContactSearchContextType";
import { getGithubName } from "@openqlabs/utils";

const ContactSearchReducer = (
  state: ContactSearchContextType,
  action: ActionOptionsWithPayload
): ContactSearchContextType => {
  switch (action.type) {
    case ADD_REPO_CONTACT: {
      const url = action.payload.tempId;
      const nameWithOwner = getGithubName(url);
      const removed = state.removedContacts.includes(url);
      const inCategoryContacts = state.repoContacts.some(
        (contact) => contact.tempId === url
      );
      if (removed || inCategoryContacts) {
        return state;
      }
      const currentContacts = state.repoContacts;

      return {
        ...state,
        repoContacts: [
          ...currentContacts,
          {
            tempId: nameWithOwner,
            customFields: action.payload.customFields,
          },
        ],
      };
    }
    case ADD_USER_CONTACT: {
      const url = action.payload.tempId;
      const login = getGithubName(url);
      const userContacts = state.userContacts;

      const removed = state.removedContacts.includes(login);
      const inUserContacts = state.userContacts.some(
        (contact) => contact.tempId === login
      );
      if (removed || inUserContacts) {
        return state;
      }
      return {
        ...state,
        userContacts: [
          ...userContacts,
          {
            tempId: login,
            customFields: action.payload.customFields,
          },
        ],
      };
    }
    case REMOVE_CONTACT: {
      const tempId = action.payload.metadata;
      const repoContacts = state.repoContacts.filter(
        (contact) => contact.tempId !== tempId
      );
      const userContacts = state.userContacts.filter(
        (contact) => contact.tempId !== tempId
      );
      return {
        ...state,
        repoContacts,
        userContacts,
        removedContacts: [...state.removedContacts, tempId],
      };
    }
    case REMOVE_CONTACT_TEMP: {
      const tempId = action.payload.metadata;
      const repoContacts = state.repoContacts.filter(
        (contact) => contact.tempId !== tempId
      );
      const userContacts = state.userContacts.filter(
        (contact) => contact.tempId !== tempId
      );
      return {
        ...state,
        repoContacts,
        userContacts,
      };
    }
    case SET_SEARCHING: {
      const isSearching = action.payload.metadata;
      return {
        ...state,
        searching: isSearching,
      };
    }
    case SET_REMOVED_CONTACTS: {
      const removedContacts = action.payload.metadata;
      return {
        ...state,
        removedContacts,
      };
    }
    case SET_SHOW_ORGS: {
      const showOrgs = action.payload.metadata;
      return {
        ...state,
        showOrgs,
      };
    }
    case SET_SHOW_REPOS: {
      const showRepos = action.payload.metadata;
      return {
        ...state,
        showRepos,
      };
    }
    case SET_SHOW_USERS: {
      const showUsers = action.payload.metadata;
      return {
        ...state,
        showUsers,
      };
    }
    case SET_ACTIVE_CONTACTS: {
      const activeContacts = action.payload.metadata;
      return {
        ...state,
        activeContacts,
      };
    }
    case SET_HAS_TOO_MANY_CONTACTS: {
      return {
        ...state,
        hasTooManyContacts: action.payload.metadata,
      };
    }
    case REMOVE_ALL_CONTACTS: {
      return {
        ...state,
        repoContacts: [],
        userContacts: [],
        removedContacts: [],
      };
    }
    case SET_FULL_ORG: {
      const fullOrg = action.payload.metadata;
      return {
        ...state,
        fullOrg,
      };
    }

    default:
      return state;
  }
};
export default ContactSearchReducer;
