import { useLatestEvaluation } from "~/providers/EvaluationProvider";
import { type RepoEvaluation } from "@openqlabs/drm-db";
import WaitingForFirstEvaluation from "~/components/lists/MainPage/Cards/SharedCard/WaitingForFirstEvaluation";
import Developer from "~/components/lists/MainPage/Cards/RepoCard/tabs/Contributors/Developer";
import { api } from "~/server/trpc/react";

export default function DevelopersTab() {
  const latestEvaluation = useLatestEvaluation<RepoEvaluation>();
  const { data: contact } = api.listContact.getContactByGithubName.useQuery({
    listId: latestEvaluation?.listId,
    teamAccountId: latestEvaluation?.teamAccountId,
    githubName: latestEvaluation?.params?.login,
  });

  if (!latestEvaluation) {
    return <WaitingForFirstEvaluation />;
  }
  return (
    <div>
      <div className="flex flex-col text-lg font-semibold text-black">
        The following contributors are working on this repository.
      </div>
      {latestEvaluation?.listId && (
        <div className="py-3 text-lg">
          Add a Contributor to your list contacts to learn more about them. This
          will allow you to analyze each one and search for relevant activities.
        </div>
      )}
      <div className="space-y-6 divide-y-2">
        {contact?.contributors?.map((author) => (
          <Developer key={author.login} mentionableUser={author} />
        ))}
      </div>
    </div>
  );
}
