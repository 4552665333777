"use client";
import React, { createContext, useContext } from "react";
import useOnlineIndicator from "~/hooks/useOnlineIndicator";
import {
  api,
  type RouterInputs,
  type RouterOutputs,
} from "~/server/trpc/react";
import { useLogger } from "./LoggerProvider";
import { useTeamAccount } from "./TeamAccountProvider";
import { useFolders } from "./FolderProvider";
import { useRouter } from "next/navigation";

export type ListsWithEvaluationDate = RouterOutputs["list"]["listsList"];
interface ListContextType {
  lists: ListsWithEvaluationDate;
  createList: (input: RouterInputs["list"]["listCreate"]) => void;
  handleRefetch: () => void;
}
const ListsContext = createContext<ListContextType>({
  lists: [],
  handleRefetch: () => null,
  createList: () => null,
});

export function useLists() {
  return useContext(ListsContext);
}

export function ListsProvider({ children }: { children: React.ReactNode }) {
  const { activeTeamAccount } = useTeamAccount();
  const logger = useLogger();
  const isOnline = useOnlineIndicator();
  const { handleRefetch: refetchFolders } = useFolders();
  const router = useRouter();
  const { data: lists, refetch } = api.list.listsList.useQuery(
    {
      teamAccountId: activeTeamAccount.id,
    },
    {
      enabled: isOnline,
    }
  );
  const { mutate: createList } = api.list.listCreate.useMutation({
    onSuccess: async (data) => {
      console.log("list created");
      await handleRefetch();
      await refetchFolders();
      router.push(`/lists/${data.id}/dependency-tracking`);
    },
  });

  const handleRefetch = () => {
    refetch().catch((err) => logger.error(err, "ListsProvider.tsx"));
  };

  if (!lists) return null;

  return (
    <ListsContext.Provider
      value={{
        lists,
        handleRefetch,
        createList,
      }}
    >
      {children}
    </ListsContext.Provider>
  );
}
