import { GitHubLogoIcon } from "@radix-ui/react-icons";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { signIn, useSession } from "next-auth/react";
import { useEffect } from "react";
import { useLogger } from "~/providers/LoggerProvider";
import Button from "../../base/Button";

export default function ConnectGithub() {
  const logger = useLogger();
  const { data: session } = useSession();
  useEffect(() => {
    if (session?.accessToken) {
      document.cookie = `github_oauth_token_unsigned=${session?.accessToken}; path=/;`;
    }
  }, [session?.accessToken]);

  function handleSignIn() {
    signIn("github").catch((err) => logger.error(err, "ConnectGithub.tsx"));
  }

  return (
    <Button
      onClick={handleSignIn}
      className="flex gap-4 w-2/3 justify-center px-6 py-4 text-2xl font-bold"
    >
      <GitHubLogoIcon className="mr-4 h-8 w-8" />
      Connect to GitHub
      <ArrowRightIcon className="ml-4 h-8 w-8" />
    </Button>
  );
}
