import type { ChartOptions } from "chart.js";

export const options: ChartOptions<"line"> = {
  aspectRatio: 10,
  layout: {
    padding: {
      top: 5,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    filler: {
      drawTime: "beforeDatasetsDraw",
      propagate: true,
    },
  },
  animation: false,
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
    },
  },
};

export const optionsBothAxisAndStackedBar: ChartOptions<"bar" | "line"> = {
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 5,
    },
  },
  animation: false,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      display: true,
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        maxTicksLimit: 20,
        minRotation: 50,
      },
    },
    "bar-y-axis": {
      stacked: true,
      display: true,
      position: "left",
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: "Active Commits By Repo", // Label for the x-axis
      },
      ticks: {
        precision: 0,
      },
    },
    "line-y-axis": {
      display: true,
      position: "right",
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: "Overall Activity", // Label for the x-axis
      },
      ticks: {
        precision: 0,
      },
    },
  },
};
