export function getEightMonthsAgo(date: Date) {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() - 8);
  return newDate;
}

export const getLocalDateString = (date: Date) => {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const monthString = month < 10 ? `0${month}` : month;
  const day = date.getUTCDate();
  const dayString = day < 10 ? `0${day}` : day;
  return `${year}-${monthString}-${dayString}`;
};

export const validateDateFormat = (date: string) => {
  if (!date) return true;
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  const test = dateRegex.test(date);
  if (!test) {
    return false;
  }
  const testDate = new Date(date);
  if (testDate.toString() === "Invalid Date") {
    return false;
  }
  return true;
};
