import { useState } from "react";

export const useHeaderMenu = (): [string, (menu: string) => void] => {
  const [activeHeaderMenu, setActiveHeaderMenu] = useState(
    () => localStorage.getItem("headerMenuState") || "Teams"
  );
  const handleSetActiveHeaderMenu = (menu: string) => {
    setActiveHeaderMenu(menu);
    if (menu === "Teams" || menu === "Contributors") {
      localStorage.setItem("headerMenuState", menu);
    }
  };
  return [activeHeaderMenu, handleSetActiveHeaderMenu];
};
