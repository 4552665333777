import React, { createContext, useContext } from "react";
import useOnlineIndicator from "~/hooks/useOnlineIndicator";
import { api } from "~/server/trpc/react";
import { RouterOutputs, RouterInputs } from "~/server/trpc/react";
import { useLogger } from "./LoggerProvider";
import { useTeamAccount } from "./TeamAccountProvider";

interface FolderContextType {
  folders: RouterOutputs["folder"]["foldersList"];
  createFolder: (input: RouterInputs["folder"]["folderCreate"]) => void;
  handleRefetch: () => void;
}
const FoldersContext = createContext<FolderContextType>({
  folders: [],
  handleRefetch: () => null,
  createFolder: () => null,
});

export function useFolders() {
  return useContext(FoldersContext);
}

export function FoldersProvider({ children }: { children: React.ReactNode }) {
  const { activeTeamAccount } = useTeamAccount();
  const logger = useLogger();
  const isOnline = useOnlineIndicator();

  const { data: folders, refetch } = api.folder.foldersList.useQuery(
    {
      teamAccountId: activeTeamAccount.id,
    },
    {
      enabled: isOnline,
    }
  );
  const { mutate: createFolder } = api.folder.folderCreate.useMutation({
    onSuccess: () => {
      handleRefetch();
    },
    onError: (error) => {
      logger.error(error, "FolderProvider.tsx");
    },
  });

  const handleRefetch = () => {
    refetch().catch((err) => logger.error(err, "FoldersProvider.tsx"));
  };
  if (!folders) return null;

  return (
    <FoldersContext.Provider
      value={{
        folders,
        handleRefetch,
        createFolder,
      }}
    >
      {children}
    </FoldersContext.Provider>
  );
}
