import { useContext } from "react";
import { useGithubContact } from "~/providers/GithubContactProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import type { RepoContact, UserContact } from "@openqlabs/drm-db";
import { api } from "~/server/trpc/react";
import ComposerContext from "../ComposerStore/ComposerContext";
import { useTeamContact } from "~/providers/TeamContactProvider";
import Button from "~/components/base/Button";

const SendButton = ({ refetch }: { refetch: () => void }) => {
  const { githubContact } = useGithubContact<RepoContact | UserContact>();
  const { teamContact } = useTeamContact();
  const { state, dispatch } = useContext(ComposerContext);
  const { body, subject } = state;
  const { teamAccountUser } = useTeamAccountUser();

  const { mutate: createNote } = api.note.addNote.useMutation({
    onSuccess: refetch,
  });
  const { activeTeamAccount } = useTeamAccount();

  const { composeType } = state;
  const setComposeType = (composeType: string) => {
    dispatch({ type: "SET_COMPOSE_TYPE", payload: composeType });
  };
  const handleSend = () => {
    if (composeType !== "Email" && teamAccountUser) {
      const noteIngredients = {
        subject,
        from: teamAccountUser.email,
        text: body,
        teamAccountId: activeTeamAccount.id,
        teamAccountUserId: teamAccountUser.id,
        teamContactId: teamContact?.id ?? null,
        githubContactId: githubContact?.id,
      };
      createNote(noteIngredients);
    }
    const initialState = state.getInitial();
    dispatch({ type: "RESET", payload: initialState });
    setComposeType("");
  };
  return (
    <div className="flex h-14 content-center items-center rounded-b-lg border-x border-b px-8">
      <Button onClick={handleSend}>Send</Button>
    </div>
  );
};

export default SendButton;
