export default function DependenciesLegend() {
  return (
    <div className="flex flex-wrap items-center gap-2 py-1 text-xs">
      <div className="flex items-center gap-2 mr-2">
        <div className={`h-3 w-3 rounded-full bg-new`} />
        <div>First used during list</div>
      </div>
      <div className="flex items-center gap-2 mr-2">
        <div className={`h-3 w-3 rounded-full bg-customer`} />
        <div>Used before list start</div>
      </div>
      <div className="flex items-center gap-2 mr-2">
        <div className={`h-3 w-3 rounded-full bg-churned`} />
        <div>Removed before list</div>
      </div>
      <div className="flex items-center gap-2 mr-2">
        <div className={`h-3 w-3 rounded-full bg-cold`} />
        <div>Never Used</div>
      </div>
      <div className="flex items-center gap-2">
        <div className={`h-3 w-3 rounded-full bg-blue-200`} />
        <div>No commits in last 6 weeks</div>
      </div>
    </div>
  );
}
