export const getStatusColor = (status: string) => {
  switch (status) {
    case "lead":
    case "lead inactive":
    case "lead churned":
      return "bg-new";
    // "new leads" = leads generated after the list end date
    case "new lead":
    case "new lead inactive":
    case "new lead churned":
      return "bg-new";
    case "customer":
    case "customer inactive":
    case "customer churned":
      return "bg-customer";
    case "churned":
      return "bg-churned";
    case "inactive":
      return "bg-blue-200";
    case "cold":
      return "bg-cold";
    default:
      return "bg-gray-100";
  }
};

export const getStatusText = (status: string) => {
  switch (status) {
    case "lead":
      return "lead";
    case "new lead":
      return "new lead";
    case "lead inactive":
      return "lead 😴";
    case "new lead inactive":
      return "new lead 😴";
    case "lead churned":
      return "lead ❌";
    case "new lead churned":
      return "new lead ❌";
    case "customer":
      return "customer";
    case "customer inactive":
      return "customer 😴";
    case "customer churned":
      return "customer ❌";
    case "churned":
      return "churned";
    case "cold":
      return "cold";
    case "inactive":
      return "inactive";
    default:
      return "no status";
  }
};

export const getRepoTooltip = (
  status: string,
  earliestDate: string,
  dateRemoved: string | null,
  manualStatus?: boolean
) => {
  if (manualStatus) {
    return "Repo manually marked active";
  }
  const earliestDateFormatted = `${earliestDate.split("T")[0] ?? ""}`;
  const dateRemovedFormatted = `${dateRemoved?.split("T")[0] ?? ""} `;
  switch (status) {
    case "lead":
      return `Active since ${earliestDateFormatted}`;
    case "new lead":
      return `Post-list lead, active since ${earliestDateFormatted}`;
    case "lead inactive":
      return `Added on ${earliestDateFormatted} but repository inactive for the last 6 weeks`;
    case "new lead inactive":
      return `Post-list lead, added on ${earliestDateFormatted} but repository inactive for the last 6 weeks`;
    case "lead churned":
      return `Added on ${earliestDateFormatted} but removed on ${
        dateRemovedFormatted ?? ""
      }`;
    case "new lead churned":
      return `Post-list lead, added on ${earliestDateFormatted} but removed on ${
        dateRemovedFormatted ?? ""
      }`;
    case "customer":
      return `Active since ${earliestDateFormatted}`;
    case "customer inactive":
      return `Added on ${earliestDateFormatted} but repository inactive for the last 6 weeks`;
    case "customer churned":
      return `Added on ${earliestDateFormatted} but removed on ${
        dateRemovedFormatted ?? ""
      }`;
    case "churned":
      return `Dependency removed on ${dateRemovedFormatted ?? ""}`;
    case "inactive":
      return `Added on ${earliestDateFormatted} but inactive since list start`;
    case "cold":
      return "Dependency not found in repository";
    default:
      return "";
  }
};
