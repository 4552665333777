import { ArrowLeftStartOnRectangleIcon } from "@heroicons/react/24/outline";
import { signOut, useSession } from "next-auth/react";
import Image from "next/image";
import { useRef, useState } from "react";
import useOnClickOutside from "~/hooks/useOnClickOutside";
import { useLogger } from "~/providers/LoggerProvider";
import ChevronCorrectIcon from "./ChevronCorrectIcon";

export default function Profile() {
  const { data } = useSession();
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const logger = useLogger();
  const ref = useRef(null);
  useOnClickOutside(ref, () => setShowProfileDropdown(false));
  if (!data) return null;
  if (!data.user.image || !data.user.name) return null;
  const openProfileDropdown = () => {
    setShowProfileDropdown(!showProfileDropdown);
  };
  const handleSignout = () => {
    signOut().catch((err) => logger.error(err, "Profile.tsx"));
  };

  return (
    <div ref={ref} className="relative z-10 flex gap-1">
      <button onClick={openProfileDropdown}>
        <Image
          width={30}
          height={30}
          className="rounded-full"
          alt={data.user.name}
          src={data.user.image}
        />
      </button>
      <button onClick={openProfileDropdown}>
        <ChevronCorrectIcon
          open={showProfileDropdown}
          className="relative top-1 h-4 w-4 stroke-dark"
        />
      </button>
      {showProfileDropdown && (
        <ul className="list-style-none absolute right-0 top-9 z-50 w-48 cursor-pointer space-y-4 rounded-md border border-vlight bg-offwhite p-6 font-medium shadow-lg">
          <li>
            <button
              className="flex w-full justify-between"
              onClick={handleSignout}
            >
              Sign out <ArrowLeftStartOnRectangleIcon className="sm-icon" />
            </button>
          </li>
        </ul>
      )}
    </div>
  );
}
