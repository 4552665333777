import { z } from "zod";

/**
 * Specify your server-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars.
 */
const server = z.object({
  BASE_URL: z.string().url(),
  ALIAS_EMAIL_DOMAIN: z.string(),
  MAILGUN_API_KEY: z.string(),
  MAILGUN_ENABLED: z.string(),
  DATABASE_URL: z.string().url(),
  EMAIL_ENABLED: z.string(),
  NODE_ENV: z.enum(["development", "test", "production"]),
  NEXTAUTH_SECRET:
    process.env.NODE_ENV === "production"
      ? z.string().min(1)
      : z.string().min(1).optional(),
  NEXTAUTH_URL: z.string().url(),
  // Add `.min(1) on ID and SECRET if you want to make sure they're not empty
  NEXTAUTH_GITHUB_CLIENT_ID: z.string(),
  NEXTAUTH_GITHUB_CLIENT_SECRET: z.string(),
  NEXTAUTH_DISCORD_CLIENT_ID: z.string(),
  NEXTAUTH_DISCORD_CLIENT_SECRET: z.string(),
  NEXTAUTH_GOOGLE_CLIENT_ID: z.string(),
  NEXTAUTH_GOOGLE_CLIENT_SECRET: z.string(),
  NEXTAUTH_SLACK_CLIENT_ID: z.string(),
  NEXTAUTH_SLACK_CLIENT_SECRET: z.string(),
  NEXTAUTH_LINKEDIN_CLIENT_ID: z.string(),
  NEXTAUTH_LINKEDIN_CLIENT_SECRET: z.string(),
  NEXT_PUBLIC_MAINTENANCE_MODE: z.string().optional(),
  NEXT_PUBLIC_GITGURU_SERVERSIDE_URL: z.string().optional(),
  OPENAI_API_KEY: z.string().optional(),
  CLIENT_PROPERTIES_PATH: z.string().optional(),
  CHAT_COMPLETIONS_ENABLED: z.string().optional(),
  NEXT_PUBLIC_BUILD_ID: z.string(),
  EMAIL_SECRET_KEY: z.string(),
  EMAIL_SECRET_IV: z.string(),
  GH_ACCESS_TOKEN: z.string(),
  EMAIL_ENCRYPTION_METHOD: z.custom(
    (val) => val === "aes-256-cbc" || val === "aes-192-cbc"
  ),
  NEXT_PUBLIC_LOG_DISABLED: z.string().optional(),
  KAFKA_BROKER_URLS: z.string().optional(),
});

/**
 * Specify your client-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars. To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
const client = z.object({
  NEXT_PUBLIC_ENV: z.enum(["development", "staging", "production"]),
  NEXT_PUBLIC_GITGURU_URL: z.string().url(),
  NEXT_PUBLIC_BUILD_ID: z.string(),
  NEXT_PUBLIC_GITGURU_SERVERSIDE_URL: z.string().optional(),
  NEXT_PUBLIC_LOG_DISABLED: z.string().optional(),
});

/**
 * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
 * middlewares) or client-side so we need to destruct manually.
 *
 * @type {Record<keyof z.infer<typeof server> | keyof z.infer<typeof client>, string | undefined>}
 */
const processEnv = {
  GH_ACCESS_TOKEN: process.env.GH_ACCESS_TOKEN,
  BASE_URL: process.env.BASE_URL,
  NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
  NEXT_PUBLIC_BUILD_ID: process.env.NEXT_PUBLIC_BUILD_ID,
  NEXT_PUBLIC_GITGURU_URL: process.env.NEXT_PUBLIC_GITGURU_URL,
  NEXT_PUBLIC_MAINTENANCE_MODE: process.env.NEXT_PUBLIC_MAINTENANCE_MODE,
  DATABASE_URL: process.env.DATABASE_URL,
  NODE_ENV: process.env.NODE_ENV,
  NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
  NEXTAUTH_URL: process.env.NEXTAUTH_URL,
  NEXTAUTH_GITHUB_CLIENT_ID: process.env.NEXTAUTH_GITHUB_CLIENT_ID,
  NEXTAUTH_GITHUB_CLIENT_SECRET: process.env.NEXTAUTH_GITHUB_CLIENT_SECRET,
  NEXTAUTH_DISCORD_CLIENT_ID: process.env.NEXTAUTH_DISCORD_CLIENT_ID,
  NEXTAUTH_DISCORD_CLIENT_SECRET: process.env.NEXTAUTH_DISCORD_CLIENT_SECRET,
  NEXTAUTH_GOOGLE_CLIENT_ID: process.env.NEXTAUTH_GOOGLE_CLIENT_ID,
  NEXTAUTH_GOOGLE_CLIENT_SECRET: process.env.NEXTAUTH_GOOGLE_CLIENT_SECRET,
  NEXTAUTH_SLACK_CLIENT_ID: process.env.NEXTAUTH_SLACK_CLIENT_ID,
  NEXTAUTH_SLACK_CLIENT_SECRET: process.env.NEXTAUTH_SLACK_CLIENT_SECRET,
  NEXTAUTH_LINKEDIN_CLIENT_ID: process.env.NEXTAUTH_LINKEDIN_CLIENT_ID,
  NEXTAUTH_LINKEDIN_CLIENT_SECRET: process.env.NEXTAUTH_LINKEDIN_CLIENT_SECRET,
  ALIAS_EMAIL_DOMAIN: process.env.ALIAS_EMAIL_DOMAIN,
  EMAIL_ENABLED: process.env.EMAIL_ENABLED,
  MAILGUN_API_KEY: process.env.MAILGUN_API_KEY,
  MAILGUN_ENABLED: process.env.MAILGUN_ENABLED,
  OPENAI_API_KEY: process.env.OPENAI_API_KEY,
  CLIENT_PROPERTIES_PATH: process.env.CLIENT_PROPERTIES_PATH,
  CHAT_COMPLETIONS_ENABLED: process.env.CHAT_COMPLETIONS_ENABLED,
  EMAIL_ENCRYPTION_METHOD: process.env.EMAIL_ENCRYPTION_METHOD,
  EMAIL_SECRET_KEY: process.env.EMAIL_SECRET_KEY,
  EMAIL_SECRET_IV: process.env.EMAIL_SECRET_IV,
  NEXT_PUBLIC_GITGURU_SERVERSIDE_URL:
    process.env.NEXT_PUBLIC_GITGURU_SERVERSIDE_URL,
  NEXT_PUBLIC_LOG_DISABLED: process.env.LOG_DISABLED,
  KAFKAJS_NO_PARTITIONER_WARNING: process.env.KAFKAJS_NO_PARTITIONER_WARNING,
  KAFKA_BROKER_URLS: process.env.KAFKA_BROKER_URLS,
};

// Don't touch the part below
// --------------------------

const merged = server.merge(client);

/** @typedef {z.input<typeof merged>} MergedInput */
/** @typedef {z.infer<typeof merged>} MergedOutput */
/** @typedef {z.SafeParseReturnType<MergedInput, MergedOutput>} MergedSafeParseReturn */

let env = /** @type {MergedOutput} */ (process.env);

if (!process.env.SKIP_ENV_VALIDATION) {
  const isServer = typeof window === "undefined";

  const parsed = /** @type {MergedSafeParseReturn} */ (
    isServer
      ? merged.safeParse(processEnv) // on server we can validate all env vars
      : client.safeParse(processEnv) // on client we can only validate the ones that are exposed
  );

  if (parsed.success === false) {
    const invalidEnvVars = Object.keys(parsed.error.flatten().fieldErrors);
    console.error(
      "❌ Invalid environment variables:",
      parsed.error.flatten().fieldErrors
    );
    console.error(
      "❌ The following environment variables failed validation:",
      invalidEnvVars.join(", ")
    );
    throw new Error(
      `Invalid environment variables: ${invalidEnvVars.join(", ")}`
    );
  }

  // eslint-disable-next-line no-undef
  env = new Proxy(parsed.data, {
    get(target, prop) {
      if (typeof prop !== "string") return undefined;
      // Throw a descriptive error if a server-side env var is accessed on the client
      // Otherwise it would just be returning `undefined` and be annoying to debug
      if (!isServer && !prop.startsWith("NEXT_PUBLIC_"))
        throw new Error(
          process.env.NODE_ENV === "production"
            ? "❌ Attempted to access a server-side environment variable on the client"
            : `❌ Attempted to access server-side environment variable '${prop}' on the client`
        );
      return target[/** @type {keyof typeof target} */ (prop)];
    },
  });
}

export { env };
