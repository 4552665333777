export const ADD_USER_CONTACT = "ADD_USER_CONTACT";
export const ADD_REPO_CONTACT = "ADD_REPO_CONTACT";
export const REMOVE_CONTACT = "REMOVE_CONTACT";
export const REMOVE_CONTACT_TEMP = "REMOVE_CONTACT_TEMP";
export const SET_REMOVED_CONTACTS = "SET_REMOVED_CONTACTS";
export const SET_SEARCHING = "SET_SEARCHING";
export const SET_SHOW_REPOS = "SET_SHOW_REPOS";
export const SET_SHOW_USERS = "SET_SHOW_USERS";
export const SET_SHOW_ORGS = "SET_SHOW_ORGS";
export const SET_ACTIVE_CONTACTS = "SET_ACTIVE_CONTACTS";
export const SET_HAS_TOO_MANY_CONTACTS = "SET_HAS_TOO_MANY_CONTACTS";
export const REMOVE_ALL_CONTACTS = "REMOVE_ALL_CONTACTS";
export const SET_FULL_ORG = "SET_FULL_ORG";
