import DashboardCard from "~/components/lists/MainPage/Dashboard/DashboardCard";
import { useList } from "~/providers/ListProvider";
import { InfoIcon } from "@primer/octicons-react";

const PrimaryInsights = ({
  leads,
  newLeads,
  customers,
  repoCount,
  userCount,
  activeTeams,
}: {
  leads: number;
  newLeads: number;
  customers: number;
  repoCount: number;
  userCount: number;
  activeTeams: number;
  dependency: string;
}) => {
  const list = useList();

  return (
    <div>
      <div className="max-w-[1220px]">
        <div className="flex justify-between pt-2 ">
          <div className="flex items-center gap-2">
            <h3 className="text-xl font-semibold">Primary Insights</h3>
            <div className="group flex">
              <InfoIcon />
              <div className="relative z-50 opacity-0 group-hover:opacity-100">
                <div className="absolute -mt-6 ml-4 w-96 bg-gray-100 px-1 text-sm">
                  These insights are currently based on an analysis of max. 25
                  repos per year & contributor since 2020. In our closed beta,
                  we also filter out repositories exceeding 100 MB in size. Due
                  to these current settings, although the majority of insights
                  will be accurate, some users may be appearing to be inactive
                  when they are not.
                </div>
              </div>
            </div>
          </div>
          <div className="flex content-center items-center justify-between gap-4 font-medium">
            <div>Budget</div>
            <div className="h-min rounded-full bg-mauve-100 px-3 py-0.5 text-sm ">
              ${list.budget}
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center pt-2 pb-4">
          <div className="">From: {list.startDate.toLocaleDateString()}</div>
        </div>
      </div>
      <div className="flex flex-wrap gap-6">
        {userCount > 0 && (
          <>
            <DashboardCard
              leadText="Evaluated users & teams"
              percentage={repoCount?.toString()}
              metricName="Total users & teams"
              leads={`${userCount}`}
            />
            <DashboardCard
              leadText="Direct leads"
              percentage={`${((leads / userCount) * 100 || 0).toFixed(2)}%`}
              metricName="List leads"
              leads={leads.toString()}
              tooltipText="Leads generated during the list period"
            />
            <DashboardCard
              leadText="Follow-up leads"
              percentage={`${((newLeads / userCount) * 100 || 0).toFixed(2)}%`}
              metricName="Post-list leads"
              leads={newLeads.toString()}
              tooltipText="Leads generated after the list period"
            />
            <DashboardCard
              leadText="Returning customers"
              percentage={`${((customers / userCount) * 100 || 0).toFixed(2)}%`}
              metricName="Returning users"
              leads={customers.toString()}
            />
            <DashboardCard
              leadText="Leads & customers"
              percentage={activeTeams.toString()}
              metricName="Total users & teams"
              leads={`${leads + customers + newLeads}`}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default PrimaryInsights;
