import { useRouter, useSearchParams } from "next/navigation";

import { useEffect } from "react";
import { useLogger } from "~/providers/LoggerProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { api } from "~/server/trpc/react";

export default function useInvite(setTeamAccounts: () => void) {
  const params = useSearchParams();

  const code = params.get("code");
  const router = useRouter();
  const logger = useLogger();
  const { mutate } = api.teamAccount.joinTeam.useMutation({
    onSuccess: () => {
      router.push("/settings");
      setTeamAccounts();
    },
  });
  const { teamAccountUser } = useTeamAccountUser();
  useEffect(() => {
    if (code && teamAccountUser) {
      mutate({ code: code as string });
    }
  }, [code, mutate, router, teamAccountUser, logger]);
}
