import { useMemo } from "react";
import { SortParams, useSortParams } from "~/providers/SortParamsProvider";
import { useList } from "~/providers/ListProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { RouterInputs } from "~/server/trpc/react";

const limit = 50;

const defaultSortParams: SortParams = {
  field: "activity",
  direction: "desc",
};

export type ListParamsType = RouterInputs["listContact"]["getContactsByListId"];

const useListParams = () => {
  const list = useList();
  const { activeTeamAccount } = useTeamAccount();
  const listId = list?.id;

  const { userSort, repoSort, userSearch, repoSearch } = useSortParams();

  const userListParams: ListParamsType = {
    listId,
    teamAccountId: activeTeamAccount?.id,
    type: "user",
    searchTerm: userSearch,
    limit,
    sorting: userSort ?? defaultSortParams,
  };
  const repoListParams: ListParamsType = {
    listId,
    teamAccountId: activeTeamAccount?.id,
    type: "repo",
    searchTerm: repoSearch,
    limit,
    sorting: repoSort ?? defaultSortParams,
  };
  return useMemo(
    () => ({
      user: userListParams,
      repo: repoListParams,
    }),
    [userListParams, repoListParams]
  );
};

export default useListParams;
