import {
  UsersIcon,
  ChartBarSquareIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useParams, useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import Dashboard from "~/components/lists/MainPage/Dashboard";
import { DependencyProvider } from "~/components/lists/MainPage/Dashboard/DashboardInsights/DependencyProviders";
import Settings from "~/components/lists/Settings";
import OverviewHeader from "~/components/layout/OverviewHeader";
import { useLists } from "~/providers/ListsProvider";
import ListContactInfoDashboard from "./lists/MainPage/ListContactInfoDashboard";
import Stats from "./overview/Stats";
import SortParamsProvider from "~/providers/SortParamsProvider";
import Toast from "./lists/MainPage/Toast";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import UnassignedUsersTable from "./UnassignedUsersTable";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { api } from "~/server/trpc/react";
import { useCsvExport, useTomlExport } from "~/hooks/useExport";
import { useHeaderMenu } from "~/hooks/useHeaderMenu";

const Overview = () => {
  const { lists } = useLists();
  const { activeTeamAccount } = useTeamAccount();
  const activeTeamAccountId = activeTeamAccount?.id;

  const [activeHeaderMenu, setActiveHeaderMenu] = useHeaderMenu();
  const repoSearchState = useState("");
  const userSearchState = useState("");
  const [repoSearch] = repoSearchState;
  const [userSearch] = userSearchState;
  const [showTable, setShowTable] = useState(true);
  const router = useRouter();
  const params = useParams();
  const wasClicked = params?.overview === "true";
  const tab = params?.tab as string;
  useEffect(() => {
    if (wasClicked) {
      setActiveHeaderMenu("Teams");
      router.replace("/");
    }
    if (tab) {
      setActiveHeaderMenu(tab);
    }
  }, [tab, setActiveHeaderMenu, wasClicked]);

  const { data: repoContactsCount = 0 } =
    api.listContact.overviewContactCount.useQuery({
      teamAccountId: activeTeamAccountId,
      type: "repo",
    });
  const { data: userContactsCount = 0 } =
    api.listContact.overviewContactCount.useQuery({
      teamAccountId: activeTeamAccountId,
      type: "user",
    });
  const { downloadToml, isDownloadingToml } = useTomlExport(
    "overview",
    activeHeaderMenu,
    ""
  );
  const { downloadCsv, isDownloadingCsv } = useCsvExport(
    "overview",
    activeHeaderMenu
  );

  const getContactStatus = () => {
    switch (activeHeaderMenu[0]) {
      case "Teams":
        return repoContactsCount === 0;
      case "Contributors":
        return userContactsCount === 0;
      default:
        return false;
    }
  };
  const noContacts = getContactStatus();

  const { teamAccountUser } = useTeamAccountUser();
  const hiddenHeaders = teamAccountUser?.role === "admin" ? [] : [];
  const sortOrders = [
    { name: "Teams", icon: UsersIcon, UsersIcon },
    { name: "Contributors", icon: UsersIcon, UsersIcon },
    { name: "Stats", icon: ChartBarSquareIcon },
    { name: "Unassigned discord users", icon: UserCircleIcon },
    ...hiddenHeaders,
  ];

  if (!lists) return null;

  const eitherContributorsOrTeams =
    activeHeaderMenu === "Contributors" || activeHeaderMenu === "Teams";
  const handleDownloadTable = () => {
    downloadCsv().catch((err) => console.error(err));
  };

  const handleDownloadToml = () => {
    downloadToml().catch((err) => console.error(err));
  };

  return (
    <SortParamsProvider userSearch={userSearch} repoSearch={repoSearch}>
      <OverviewHeader
        headerMenuType={"tabs"}
        newBtnText="New List"
        newBtnHref={`/lists/new`}
        headerText={"Overview"}
        menuItems={sortOrders}
        headerMenuState={[activeHeaderMenu, setActiveHeaderMenu]}
        handleChangeSortOrder={() => {
          return void 0;
        }}
        hasTableToggle={eitherContributorsOrTeams}
        toggleTable={(val: boolean) => {
          setShowTable(val);
        }}
        downloadTable={handleDownloadTable}
        noContacts={noContacts}
        isDownloading={isDownloadingCsv}
        downloadToml={handleDownloadToml}
        isDownloadingToml={isDownloadingToml}
        showTable={showTable}
        searchState={
          eitherContributorsOrTeams
            ? activeHeaderMenu === "Contributors"
              ? userSearchState
              : repoSearchState
            : undefined
        }
      >
        <ListContactInfoDashboard
          activeHeaderMenu={activeHeaderMenu}
          showTable={showTable}
        />
        {activeHeaderMenu === "Dashboard" && (
          <DependencyProvider>
            <Dashboard setSubMenu={setActiveHeaderMenu} />
          </DependencyProvider>
        )}

        {activeHeaderMenu === "Stats" && <Stats />}

        {activeHeaderMenu === "Settings" && <Settings />}

        {activeHeaderMenu === "Unassigned discord users" && (
          <UnassignedUsersTable />
        )}

        <Toast isDashboard={true} />
      </OverviewHeader>
    </SortParamsProvider>
  );
};
export default Overview;
