import HeroPage from "../layout/HeroPage";
import LogoutProfile from "../layout/LogoutProfile";

export default function HasNoTeamAccount() {
  return (
    <HeroPage>
      <div className="relative flex h-screen w-1/2  flex-col content-center items-center justify-center">
        <div className="absolute top-0 right-0 z-10 mr-4 mt-4">
          <LogoutProfile />
        </div>
        <div className="mb-3 text-3xl font-bold text-gray-600">
          OpenQ Closed Beta
        </div>
        <div className="text mb-10 max-w-[400px] text-center">
          It looks like you haven{"'"}t been invited to a team for this closed
          beta, please contact us at{" "}
          <a
            className="text-link hover:opacity-75"
            href="mailto:info@openq.dev"
          >
            info@openq.dev
          </a>{" "}
          to request access.
        </div>
      </div>
    </HeroPage>
  );
}
